import React from 'react';

const Contact = props => (
  <section id="contact">
    <div className="inner">
      <section>
        <section>
          <div className="contact-method">
            <a href="https://www.facebook.com/QuickHit-Fitness-Lab-Oshkosh-105214631164462">
              <span className="icon alt fa-facebook"></span>
              <h3>Facebook</h3>
            </a>
          </div>
        </section>
      </section>
      <section>
        <section>
          <div className="contact-method">
            <a href="mailto:me@karmentownetraining.com">
              <span className="icon alt fa-envelope"></span>
              <h3>me@karmentownetraining.com</h3>
            </a>
          </div>
        </section>
      </section>
      <section>
        <section>
          <div className="contact-method">
            <a href="https://www.instagram.com/ktowne727">
              <span className="icon alt fa-instagram"></span>
              <h3>Instagram</h3>
            </a>
          </div>
        </section>
      </section>
    </div>
  </section>
);

export default Contact;
