import React from 'react';

const Footer = props => (
  <footer id="footer">
    <div className="inner">
      <ul className="copyright">
        <li>&copy; {new Date().getFullYear()} Karmen Towne Training LLC</li>
      </ul>
    </div>
  </footer>
);

export default Footer;
